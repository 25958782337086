window.plusOrMin = function(object) {
    if ($('li', $(object).parent().parent()).is(":visible")) {
        $(object).addClass('min');
        $(object).removeClass('plus');
        $(object).text("-");
    } else {
        $(object).addClass('plus');
        $(object).removeClass('min');
        $(object).text("+");

        $(object).parent().parent().find('.plusOrMinButton').addClass('plus');
        $(object).parent().parent().find('.plusOrMinButton').removeClass('min');
        $(object).parent().parent().find('.plusOrMinButton').text("+");
    }
}