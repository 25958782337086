jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    "dom-checkbox-pre": function(a) {
        return ((/checked/i.test(a)) ? 1 : 0);
    },
    "dom-checkbox-asc": function(a, b) {
        return a - b;
    },
    "dom-checkbox-desc": function(a, b) {
        return b - a;
    }

});