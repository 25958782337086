import Foundation from 'foundation-sites';
$(document).foundation();

if (window != top) {
    top.location.href = location.href;
}

$(function () {
    window.bLazy = new Blazy({
        src: 'data-blazy', // Default is data-src, but that has angular issues
        selector: '.lazyload'
    });

    setTimeout(function () {
        $(".content .info").hide('blind', {}, 250);
    }, 5000);
});

$(document).ready(function () {
    $('aside nav a').each(function () {
        $(this).click(function (event) {
            var fmName = $(this).closest('[data-fm-name]').data('fmName');

            var linkHref = $(this).attr('href');

            if (
                event.ctrlKey ||
                event.shiftKey ||
                event.metaKey || // apple
                (event.button && event.button == 1) // middle click, >IE9 + everyone else
            ) {
                event.originalEvent.currentTarget.href = linkHref + (linkHref.split('?')[1] ? '&':'?') + "setLayoutLoadMenu=" + fmName;
            } else {
                $('main').html('<div style="color: #999; text-align: center; padding-top: 50px; padding-bottom: 150px;">Loading ...</div>');

                $.ajax({
                    method: 'POST',
                    url: '/showMenu',
                    data: {'setLayoutLoadMenu': fmName}
                }).always(function () {
                    window.location.href = linkHref;
                });

                return false;
            }
        });
    });
});
