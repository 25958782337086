// JavaScript Document
window.validate_required = function(field, fieldFullName, allowEmpty = false) {
    if ($('[name=' + field + ']')) {
        field = $('[name=' + field + ']');
    }

    if (fieldFullName == null) {
        fieldFullName = field.attr('name');
    }

    if (field.val() == null || field.val() == "") {
        if (allowEmpty == true) {
            return true;
        } else if (field.is('select')) {
            alert("Bij '"+fieldFullName+"' dient u een van de beschikbare opties te selecteren!");
            field.focus();
        } else {
            alert("U bent verplicht om het veld '" + fieldFullName + "' correct in te vullen.");
            field.focus();
        }
        return false;
    } else {
        return true;
    }
}

window.validate_required_radio = function(field, fieldFullName) {
    if ($('[name=' + field + ']')) {
        field = $('[name=' + field + ']');
    }

    if (field.attr('type', 'radio')) {
        if (getCheckedValue(field) == '') {
            alert("Bij '" + fieldFullName + "' moet verplicht een optie geselecteerd worden");
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

window.validate_email = function(field, fieldFullName, allowEmpty = false) {
    if ($('[name=' + field + ']')) {
        field = $('[name=' + field + ']');
    }

    if (fieldFullName == null) {
        fieldFullName = field.attr('name');
    }

    var regexp = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;

    if (field.val() == "" && allowEmpty == true) {
        return true;
    } else if (regexp.test(field.val()) == false) {
        alert("U bent verplicht om het veld '" + fieldFullName + "' correct in te vullen.");
        field.focus();
        return false;
    } else {
        return true;
    }
}

window.validate_postcode = function(field, fieldFullName, allowEmpty = false) {
    if ($('[name=' + field + ']')) {
        field = $('[name=' + field + ']');
    }

    if (fieldFullName == null) {
        fieldFullName = field.attr('name');
    }

    var regexp = /[0-9]{4}[a-zA-Z]{2}/;

    if (field.val() == "" && allowEmpty == true) {
        return true;
    } else if (regexp.test(field.val()) == false) {
        alert("U bent verplicht om het veld '" + fieldFullName + "' correct in te vullen.");
        field.focus();
        return false;
    } else {
        return true;
    }
}

window.validate_phoneNumber = function(field, fieldFullName, allowEmpty = false) {
    if ($('[name=' + field + ']')) {
        field = $('[name=' + field + ']');
    }

    if (fieldFullName == null) {
        fieldFullName = field.attr('name');
    }

    var regexp = /^[0-9]{10}$/;

    if (field.val() == "" && allowEmpty == true) {
        return true;
    } else if (regexp.test(field.val()) == false) {
        alert("U bent verplicht om het veld '"+fieldFullName+"' correct in te vullen.");
        field.focus();
        return false;
    } else {
        return true;
    }
}

window.validate_int = function(field, fieldFullName, belowZero, allowEmpty = false) {
    if ($.type(field) == 'string') {
        if ($('[name=' + field + ']')) {
            field = $('[name=' + field + ']');
        }
    }

    if (fieldFullName == null) {
        fieldFullName = field.attr('name');
    }

    if (field.val() == "" && allowEmpty == true) {
        return true;
    } else if (isNaN(field.val()) || parseInt(field.val()) != field.val() || field.val().indexOf('.') != -1) {
        alert("Het veld '" + fieldFullName + "' moet een heel getal zijn en groter dan nul");
        field.focus();
        field.val(1);
        return false;
    } else {
        if (belowZero && field.val() < 0) {
            alert("Het veld '"+fieldFullName+"' moet een heel getal zijn en groter dan nul");
            field.focus();
            field.val(1);
            return false;
        } else {
            return true;
        }
    }
}

window.validate_bankIban = function(field, fieldFullName, allowEmpty = false) {
    if ($('[name=' + field + ']')) {
        field = $('[name=' + field + ']');
    }

    if (fieldFullName == null) {
        fieldFullName = field.attr('name');
    }

    if (field.val() == "" && allowEmpty == true) {
        return true;
    } else if (window.IBAN.isValid(field.val().toUpperCase()) == false) {
        alert("U bent verplicht om het veld '" + fieldFullName + "' correct in te vullen.");
        field.focus();
        return false;
    } else {
        return true;
    }
}