window.tinyMCEInitConf_wysiwyg = {
    mode: "textareas",
    removed_menuitems: 'newdocument',
    language_url: '/plugins/tinymce/langs/nl.js',
    plugins: [
        "advlist autolink lists link image charmap hr anchor pagebreak",
        "searchreplace wordcount visualblocks visualchars code fullscreen",
        "insertdatetime media nonbreaking table contextmenu directionality",
        "paste textcolor colorpicker"
    ],
    image_advtab: true,
    fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 42px 44px 46px 48px 50px 52px 54px 56px 58px 60px",
    toolbar1: "undo redo | styleselect | fontsizeselect | fontselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | forecolor backcolor",
    editor_selector: "wysiwyg",
    toolbar_items_size: 'small',
    file_browser_callback: fileBrowser,
    auto_cleanup_word: true,
    convert_urls: false,
    relative_urls: false,
    remove_script_host: false,
    inline_styles: true,
    valid_children: "+body[style]",
    font_formats:
        "Aaargh=Aaargh,sans-serif;" +
        "Actor=Actor,sans-serif;" +
        "Andale Mono=andale mono,times;" +
        "Arial=arial,helvetica,sans-serif;" +
        "Arial Black=arial black,avant garde;" +
        "Book Antiqua=book antiqua,palatino;" +
        "Colaborate=Colaborate,sans-serif;" +
        "Comic Sans MS=comic sans ms,sans-serif;" +
        "Courier New=courier new,courier;" +
        "Delicious=Delicious,sans-serif;" +
        "Georgia=georgia,palatino;" +
        "Gudea=Gudea,sans-serif;" +
        "Helvetica=helvetica;" +
        "Impact=impact,chicago;" +
        "Symbol=symbol;" +
        "Tahoma=tahoma,arial,helvetica,sans-serif;" +
        "Terminal=terminal,monaco;" +
        "Times New Roman=times new roman,times;" +
        "Trebuchet MS=trebuchet ms,geneva;" +
        "Verdana=verdana,geneva;" +
        "Webdings=webdings;" +
        "Wingdings=wingdings,zapf dingbats",
    link_class_list: [
        {title: '-', value: ''},
        {title: 'Oranje button M', value: 'mediumButton orangeButton'},
        {title: 'Oranje button M + schaduw', value: 'buttonWithShadow mediumButton orangeButton'},
        {title: 'Oranje button L', value: 'largeButton orangeButton'},
        {title: 'Oranje button L + schaduw', value: 'buttonWithShadow largeButton orangeButton'},
        {title: 'Grijze button M', value: 'mediumButton greyButton'},
        {title: 'Grijze button M + schaduw', value: 'buttonWithShadow mediumButton greyButton'},
        {title: 'Grijze button L', value: 'largeButton greyButton'},
        {title: 'Grijze button L + schaduw', value: 'buttonWithShadow largeButton greyButton'},
        {title: 'Groene button M', value: 'mediumButton greenButton'},
        {title: 'Groene button M + schaduw', value: 'buttonWithShadow mediumButton greenButton'},
        {title: 'Groene button L', value: 'largeButton greenButton'},
        {title: 'Groene button L + schaduw', value: 'buttonWithShadow largeButton greenButton'}
    ],
    invalid_styles: {
        'table': 'width height',
        'tr' : 'width height',
        'th' : 'height',
        'td' : 'width height'
    },
    setup: function (editor) {
        editor.on('change', function () {
            editor.save();
        });
    }
};

window.tinyMCEInitConf_richtext = {
    mode: "textareas",
    language_url: '/plugins/tinymce/langs/nl.js',
    menubar: false,
    plugins: [
        "advlist autolink lists link charmap hr anchor",
        "searchreplace wordcount visualblocks visualchars code fullscreen",
        "insertdatetime nonbreaking table contextmenu directionality",
        "paste textcolor colorpicker"
    ],
    fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 42px 44px 46px 48px 50px 52px 54px 56px 58px 60px",
    toolbar1: "undo redo | styleselect | fontsizeselect | fontselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | forecolor backcolor",
    editor_selector: "richtext",
    toolbar_items_size: 'small',
    auto_cleanup_word: true,
    convert_urls: false,
    relative_urls: false,
    force_p_newlines: false,
    forced_root_block: '',
    remove_script_host: false,
    inline_styles: true,
    valid_children: "+body[style]",
    invalid_styles: {
        'table': 'width height',
        'tr' : 'width height',
        'th' : 'width',
        'td' : 'width height'
    }
};

tinyMCE.init(window.tinyMCEInitConf_wysiwyg);
tinyMCE.init(window.tinyMCEInitConf_richtext);

function fileBrowser(fieldName, url, type, win)
{
    if (type == 'image') {
        var title = 'Image browser';
        var cmsUrl = TINYMCE_FILEBROWSER_PREFIX + "/tinyMce/imageBrowser";
    } else {
        var title = 'File browser';
        var cmsUrl = TINYMCE_FILEBROWSER_PREFIX + "/tinyMce/fileBrowser";
    }

    window.fileBrowserEditor = tinyMCE.activeEditor;

    window.fileBrowserFieldName = fieldName;

    window.fileBrowserFrame = tinyMCE.activeEditor.windowManager.open({
        file: cmsUrl,
        title: title,
        width: 947,
        height: 800,
        resizable: 0,
        inline: 1,
        close_previous: 0
    });

    return false;
}

window.TinyMceInit = function() {
    tinyMCE.init(window.tinyMCEInitConf_wysiwyg);
    tinyMCE.init(window.tinyMCEInitConf_richtext);
}

window.TinyMceRemove = function() {
    tinyMCE.remove("textarea.richtext");
    tinyMCE.remove("textarea.wysiwyg");
    // var i, t = tinyMCE.editors;
    // for (i in t) {
    //     if (t.hasOwnProperty(i)) {
    //         t[i].remove();
    //     }
    // }
}