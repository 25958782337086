window.ajaxForm = function($form) {
    url = $form.attr("action");
    $submitButton = $('input[type=submit]', $form);

    $submitButton.attr('disabled', 'true');
    submitButtonOriginalValue = $submitButton.val();
    $submitButton.val('Verwerken ...');
    $('body').css('cursor', 'wait');

    return $.post(url, $form.serialize())
            .done(function(data) {
                if (data.redirect) {
                    window.location.href = data.redirect;
                } else if (data.replaceWith) {
                    $form.replaceWith(data.replaceWith);
                    $submitButton.remove();
                } else {
                    $msgDiv = $('<div class="flashdata ' + (data.level ? data.level : 'info') + '"><p>' + (data.msg ? data.msg : data) + '</p></div>').prependTo($form);
                    if (data.level === 'info' || !data.level) {
                        $msgDiv.delay(12000).hide('blind', {}, 250);
                    }
                }

                if (data.clear) {
                    $form.each(function() {
                        this.reset();
                    });
                }
            })
            .fail(function() {
                $msgDiv = $('<div class="flashdata error"><p>Onbekende fout bij het opslaan, probeer het opnieuw</p></div>').prependTo($form);
            })
            .always(function() {
                if ($submitButton) {
                    $submitButton.removeAttr('disabled');
                    $submitButton.val(submitButtonOriginalValue);
                }

                $('body').css('cursor', 'auto');

                if ($msgDiv) {
                    var offset = $msgDiv.offset().top - $(window).scrollTop();

                    if (offset > window.innerHeight || $(window).scrollTop() > offset) {
                        $('html,body').animate({scrollTop: $msgDiv.offset().top - 10});
                    }
                }
            });
}