window.changeStatusBy = function (obj, url, key, value, onSuccesCallback, onFailureCallback) {
    var imgId = ('img' + key).replace(/\,/g, '-');

    $(obj).hide();
    $(obj).parent().append('<img src="/build/images/loading_small.gif" id="' + imgId + '" style="margin:0px 0px 0px 1px;" />');

    var processResponse = function (data, onSuccesCallback, onFailureCallback) {
        if (typeof data !== 'object') {
            var msg = (data !== 'true') ? 'Sorry de bewerking kan niet succesvol afgerond worden.\n\nProbeer het later nog eens!' : null;
            var data = {status: data, msg: msg};
        }

        data['obj'] = obj;
        data['key'] = key;
        data['value'] = value;

        if (data['status'] === true || data['status'] === 'true') {
            if (typeof onSuccesCallback === "function") {
                data = onSuccesCallback(data);
            }
        } else {
            if (typeof onFailureCallback === "function") {
                data = onFailureCallback(data);
            } else if (!('msg' in data)) {
                if ('error' in data) {
                    data['msg'] = data['error'];
                } else {
                    data['msg'] = 'Sorry de bewerking kan niet succesvol afgerond worden.\n\nProbeer het later nog eens!';
                }
            }
        }

        if (data !== undefined && data['msg']) {
            alert(data['msg']);
        }
    }

    jQuery.ajax({
        type: "POST",
        url: url,
        data: {
            'key': key,
            'value': value
        },
        success: function (data) {
            processResponse(data, onSuccesCallback, onFailureCallback);
        },
        error: function (jqXHR) {
            try {
                var data = jQuery.parseJSON(jqXHR.responseText);
            } catch (e) {
                var data = {'status': false};
            }

            processResponse(data, onSuccesCallback, onFailureCallback);
        }
    }).always(function () {
        $('#' + imgId).remove();
        $(obj).show();
    });
}

window.changeStatusBySelect = function(obj, url, key, onSuccesCallback, onFailureCallback) {
    changeStatusBy(obj, url, key, $(obj).val(), onSuccesCallback, onFailureCallback);
}

window.changeStatusByInputField = function(obj, url, key, onSuccesCallback, onFailureCallback) {
    changeStatusBy(obj, url, key, $(obj).val(), onSuccesCallback, onFailureCallback);
}

window.changeStatusByCheckbox = function(obj, url, key, onSuccesCallback, onFailureCallback) {
    var value = ($(obj).is(':checked')) ? '1' : '0';

    changeStatusBy(obj, url, key, value, onSuccesCallback, function (data) {
        if (typeof onFailureCallback === "function") {
            data = onFailureCallback(data);
        }

        $(obj).prop('checked', !$(obj).prop('checked'));

        return data;
    });
}

window.changeStatusByButtonAndField = function(obj, value, url, key, onSuccesCallback, onFailureCallback) {
    changeStatusBy(obj, url, key, value, onSuccesCallback, onFailureCallback);
}

window.changeStatusByButton = function(obj, url, key, onSuccesCallback, onFailureCallback) {
    changeStatusBy(obj, url, key, 1, onSuccesCallback, onFailureCallback);
}