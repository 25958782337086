//sets up numeric sorting of links
jQuery.fn.dataTableExt.oSort['num-html-asc'] = function(a, b) {
    var x = a.replace(/<.*?>/g, "");
    var y = b.replace(/<.*?>/g, "");
    x = parseFloat(x);
    y = parseFloat(y);
    return ((x < y || isNaN(y)) ? -1 : ((x > y || isNaN(x)) ? 1 : 0));
};

jQuery.fn.dataTableExt.oSort['num-html-desc'] = function(a, b) {
    var x = a.replace(/<.*?>/g, "");
    var y = b.replace(/<.*?>/g, "");
    x = parseFloat(x);
    y = parseFloat(y);
    return ((x < y || isNaN(x)) ? 1 : ((x > y || isNaN(y)) ? -1 : 0));
};