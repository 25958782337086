jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    "date-nl-pre": function(a) {
        if ($.trim(a) != '' && $.trim(a) != '-') {
            var nlDatea = a.split('-');
            return (nlDatea[2] + nlDatea[1] + nlDatea[0]) * 1;
        } else {
            return 10000000000000; // = l'an 1000 ...
        }
    },
    "date-nl-asc": function(a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },
    "date-nl-desc": function(a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
    "datetime-nl-pre": function(a) {
        if ($.trim(a) != '' && $.trim(a) != '-') {
            var frDatea = $.trim(a).split(' ');
            var frTimea = frDatea[1].split(':');
            var frDatea2 = frDatea[0].split('-');

            if (frTimea[2] === undefined) {
                frTimea[2] = 0;
            }

            var x = (frDatea2[2] + frDatea2[1] + frDatea2[0] + frTimea[0] + frTimea[1] + frTimea[2]) * 1;
        } else {
            var x = 10000000000000; // = l'an 1000 ...
        }

        return x;
    },
    "datetime-nl-asc": function(a, b) {
        return a - b;
    },
    "datetime-nl-desc": function(a, b) {
        return b - a;
    }
});